import { inject }                  from 'aurelia-framework';
import { TimeIntervalsRepository } from 'modules/administration/time-intervals/services/repository';
import { PlanActionSemaphore }     from 'modules/legislation/client/applicability/analysis/perform/models/plan-action-semaphore';

@inject(TimeIntervalsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param timeIntervalsRepository
     */
    constructor(timeIntervalsRepository) {
        this.timeIntervalsRepository = timeIntervalsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {PlanActionSemaphore}
     */
    model() {
        let model = new PlanActionSemaphore();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.green_time_interval_amount = {
            type:            'number',
            key:             'green_time_interval_amount',
            label:           'form.field.value',
            showLabel:       false,
            size:            4,
            required:        false,
            inputGroupAddon: {
                classes: 'text-success mr-2',
                icon:    'icon-circle2',
            },
        };

        this.green_time_interval_id = {
            type:         'select2',
            key:          'green_time_interval_id',
            label:        'form.field.time-interval',
            showLabel:    false,
            size:         8,
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
            required:     false,
        };

        this.orange_time_interval_amount = {
            type:            'number',
            key:             'orange_time_interval_amount',
            label:           'form.field.value',
            showLabel:       false,
            size:            4,
            required:        false,
            inputGroupAddon: {
                classes: 'text-warning mr-2',
                icon:    'icon-circle2',
            },
        };

        this.orange_time_interval_id = {
            type:         'select2',
            key:          'orange_time_interval_id',
            label:        'form.field.time-interval',
            showLabel:    false,
            size:         8,
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
            required:     false,
        };

        this.red_time_interval_amount = {
            type:            'number',
            key:             'red_time_interval_amount',
            label:           'form.field.value',
            showLabel:       false,
            size:            4,
            required:        false,
            inputGroupAddon: {
                classes: 'text-danger mr-2',
                icon:    'icon-circle2',
            },
        };

        this.red_time_interval_id = {
            type:         'select2',
            key:          'red_time_interval_id',
            label:        'form.field.time-interval',
            showLabel:    false,
            size:         8,
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
            required:     false,
        };

        return [
            [this.green_time_interval_amount, this.green_time_interval_id],
            [this.orange_time_interval_amount, this.orange_time_interval_id],
            [this.red_time_interval_amount, this.red_time_interval_id],
        ];
    }

}
