import { bindable, inject }               from 'aurelia-framework';
import { AppContainer }                   from 'resources/services/app-container';
import { BaseViewModel }                  from 'base-view-model';
import { ActionsWithoutAlertsRepository } from 'modules/legislation/client/actions-without-alerts/services/repository';
import { EditPlanActionSemaphore }        from 'modules/legislation/client/applicability/analysis/perform/action-plan/semaphores/index';
import { PlanActionAlertListing }         from 'modules/legislation/client/applicability/analysis/perform/action-plan/alerts/index';
import { DialogService }                  from 'aurelia-dialog';

@inject(AppContainer, ActionsWithoutAlertsRepository, DialogService)
export class ListActionsWithoutAlerts extends BaseViewModel {

    headerTitle = 'listing.legislation.actions-without-alerts';
    listingId   = 'legislation-client-actions-without-alerts-listing';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.plan-actions-without-alerts.manage',
            'legislation.client.plan-actions-without-alerts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository: this.repository,
            edit:       null,
            destroy:    null,
            actions:    [
                {
                    icon:    'icon-warning',
                    tooltip: 'form.button.alerts',
                    visible: (row) => !row.has_alerts,
                    action:  (row) => this.dialogService.open({
                        viewModel: PlanActionAlertListing,
                        model:     row,
                    }).whenClosed(() => this.datatable.instance.reload()),
                },
                {
                    icon:    ' icon-traffic-lights',
                    tooltip: 'form.button.semaphores',
                    visible: (row) => !row.has_semaphore,
                    action:  (row) => this.dialogService.open({
                        viewModel: EditPlanActionSemaphore,
                        model:     row,
                    }).whenClosed(() => this.datatable.instance.reload()),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    3,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'order',
                    name:  'plan_actions.order',
                    title: 'form.field.number',
                },
                {
                    data:  'applicable_sector',
                    name:  'company_organizations.name',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:       'periodicity',
                    name:       'periodicity',
                    title:      'form.field.periodicity',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:  'first_task_implementation_deadline',
                    name:  'plan_actions.first_task_implementation_deadline',
                    title: 'form.field.implementation-deadline(1st-task)',
                },
                {
                    data:  'responsible',
                    name:  'users.name',
                    title: 'form.field.responsible',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }
}
