import { inject }           from 'aurelia-framework';
import { ViewDiplomaModal } from 'modules/legislation/diplomas/view-modal/index';
import { DialogService }    from 'aurelia-dialog';

@inject(DialogService)
export class FormSchema {

    /**
     * Constructor
     *
     * @param dialogService
     */
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.diplomas.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.viewDiploma = {
            type:       'button',
            label:      'form.button.diploma',
            action:     () => this.dialogService.open({
                viewModel: ViewDiplomaModal,
                model:     viewModel.diploma,
            }).whenClosed(() => {
                viewModel.fetchData(viewModel.diploma.id).then(() => {
                    // TODO: refactor this!!!
                    if (viewModel.creationSchemaVisible) {
                        viewModel.creationFormSchema.diploma_classifications.instance.fetchData();
                    }
                    if (viewModel.editionSchemaVisible) {
                        viewModel.editionFormSchema.diploma_classification_id.instance.fetchData();
                    }
                });
            }),
            attributes: {
                class: 'btn btn-outline bg-slate-600 text-slate-600 border-slate float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-eye',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.viewDiploma,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
