import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class ApplicabilityAnalysisRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;
        this.downloader = downloader;
    }

    /**
     * Performs a search given some criteria
     *
     * @param diplomaId
     * @param criteria
     *
     * @returns {*}
     */
    search(diplomaId, criteria) {
        return this.httpClient.post(`legislation/client/diplomas/${diplomaId}/applicability/analysis/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param diplomaId
     * @param data
     *
     * @returns {*}
     */
    create(diplomaId, data) {
        return this.httpClient.post(`legislation/client/diplomas/${diplomaId}/applicability/analysis`, data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/diplomas/applicability/analysis/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/client/diplomas/applicability/analysis/${id}`, data);
    }

    /**
     * Performs applicability analysis of a given diploma
     *
     * @param diplomaId
     * @param data
     *
     * @returns {*}
     */
    perform(diplomaId, data) {
        return this.httpClient.post(`legislation/client/diplomas/${diplomaId}/applicability/analysis/perform`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/client/diplomas/applicability/analysis/${id}`);
    }

    /**
     * Destroys a record
     *
     * @param diplomaId
     *
     * @returns {*}
     */
    notify(diplomaId) {
        return this.httpClient.post(`legislation/client/diplomas/${diplomaId}/applicability/notify`);
    }

    /**
     * Downloads report
     *
     * @param diplomaId
     * @param criteria
     *
     * @returns {*}
     */
    downloadReport(diplomaId, criteria) {
        return this.httpClient.rawPost(`legislation/client/diplomas/${diplomaId}/applicability/report`, criteria)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'applicability_report', 'pdf'));
    }

}
