import { inject }                from 'aurelia-framework';
import { UsersRepository }       from 'modules/administration/users/services/repository';
import { BaseModel }             from 'modules/core/models/base-model';

@inject(UsersRepository)
export class FormSchema {

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BaseModel}
     */
    model() {
        return new BaseModel();
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.responsible_id = {
            type:         'select2',
            key:          'responsible_id',
            id:           'plan_action_task_responsible_id',
            label:        'form.field.responsible',
            size:         12,
            remoteSource: this.usersRepository.active.bind(this.usersRepository),
        };

        this.implementation_deadline = {
            type:     'material-ui-date-picker',
            key:      'implementation_deadline',
            id:       'plan_action_task_implementation_deadline',
            label:    'form.field.implementation-deadline',
            size:     12,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        return [
            [this.responsible_id],
            [this.implementation_deadline],
        ];
    }

}
