import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class PlanActionSemaphoresRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Finds a record
     *
     * @param planActionId
     *
     * @returns {*}
     */
    find(planActionId) {
        return this.httpClient.get('legislation/client/diplomas/applicability/analysis/plan-actions/' + planActionId + '/semaphores');
    }

    /**
     * Updates a record
     *
     * @param planActionId
     * @param data
     *
     * @returns {*}
     */
    update(planActionId, data) {
        return this.httpClient.put('legislation/client/diplomas/applicability/analysis/plan-actions/' + planActionId + '/semaphores', data);
    }
}
