import { BaseModel } from 'modules/core/models/base-model';

export class PlanActionAlert extends BaseModel {

    periodicity_id            = null;
    periodicity_value         = null;
    start_time_interval_id    = null;
    start_time_interval_value = null;

}
