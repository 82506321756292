import { BaseModel } from 'modules/core/models/base-model';

export class PlanAction extends BaseModel {

    order                              = null;
    periodicity_value                  = null;
    periodicity_id                     = null;
    first_task_implementation_deadline = null;
    description                        = null;
    user_id                            = null;
    user_group_id                      = null;

}
