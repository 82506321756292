import { BaseModel } from 'modules/core/models/base-model';

export class PlanActionSemaphore extends BaseModel {

    green_time_interval_id      = null;
    green_time_interval_amount  = null;
    orange_time_interval_id     = null;
    orange_time_interval_amount = null;
    red_time_interval_id        = null;
    red_time_interval_amount    = null;

}
