import { bindable, inject }            from 'aurelia-framework';
import { AppContainer }                from 'resources/services/app-container';
import { BaseFormViewModel }           from 'base-form-view-model';
import { MyActionFollowupsRepository } from 'modules/legislation/client/personal-area/my-actions/followups/services/repository';
import { DialogController }            from 'aurelia-dialog';

@inject(AppContainer, MyActionFollowupsRepository, DialogController)
export class PlanActionTaskFollowupsListing extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.followups',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(planActionTask) {
        this.planActionTask = planActionTask;

        this.settings.title = this.appContainer.i18n.tr('form.title.followups');

        if (this.planActionTask.overview) {
            this.settings.title += ' - ' + this.planActionTask.overview;
        }

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          (criteria) => this.repository.search(this.planActionTask.id, criteria),
                destroy:         false,
                destroySelected: false,
            },
            destroy:             false,
            destroySelected:     false,
            actions:             [],
            options:             [],
            buttons:             [],
            selectable:          false,
            showOptionsDropdown: false,
            sorting:             {
                column:    1,
                direction: 'desc',
            },
            columns:             [
                {
                    data:  'overview',
                    name:  'plan_action_task_followups.overview',
                    title: 'form.field.overview',
                },
                {
                    data:            'created_at',
                    name:            'plan_action_task_followups.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'creator',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }

}
