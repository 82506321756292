import { bindable, inject, PLATFORM }           from 'aurelia-framework';
import { AppContainer }                         from 'resources/services/app-container';
import { BaseViewModel }                        from 'base-view-model';
import { ApplicabilityAnalysisRepository }      from 'modules/legislation/client/applicability/analysis/services/repository';
import { ApplicabilityAnalysisFilesRepository } from 'modules/legislation/client/applicability/analysis/services/files-repository';
import { DiplomasRepository }                   from 'modules/legislation/diplomas/services/repository';
import { ActionPlanListing }                    from 'modules/legislation/client/applicability/analysis/perform/action-plan/index';
import { FormSchema }                           from 'modules/legislation/client/applicability/analysis/perform/form-schema';
import { CreationFormSchema }                   from 'modules/legislation/client/applicability/analysis/perform/creation-form-schema';
import { EditionFormSchema }                    from 'modules/legislation/client/applicability/analysis/perform/edition-form-schema';
import { ListFilesModal }                       from 'modules/administration/files/modal/index';
import { GenerateApplicabilityReport }          from 'modules/legislation/client/applicability/analysis/perform/report/index';
import { ConfirmNotifyUsers }                   from 'modules/legislation/client/applicability/analysis/perform/dialogs/confirm-notify-users';
import { DialogService }                        from 'aurelia-dialog';

@inject(AppContainer, ApplicabilityAnalysisRepository, ApplicabilityAnalysisFilesRepository, DiplomasRepository, FormSchema, CreationFormSchema, EditionFormSchema, DialogService)
export class PerformApplicabilityAnalysis extends BaseViewModel {

    headerTitle = 'form.title.applicability-analysis';
    listingId   = 'legislation-client-applicability-analysis-listing';
    formId      = 'legislation-client-applicability-analysis-perform-form';

    creationSchemaVisible = false;
    editionSchemaVisible  = false;

    displayCreationSchemaNotice = false;
    displayEditionSchemaNotice  = false;

    @bindable fileSettings = {
        relatableModel:    null,
        repositoryBaseUri: 'diplomas/applicability/analysis/',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param diplomasRepository
     * @param formSchema
     * @param dialogService
     * @param creationFormSchema
     * @param editionFormSchema
     */
    constructor(appContainer, repository, filesRepository, diplomasRepository, formSchema, creationFormSchema, editionFormSchema, dialogService) {
        super(appContainer);

        this.repository         = repository;
        this.filesRepository    = filesRepository;
        this.diplomasRepository = diplomasRepository;
        this.formSchema         = formSchema;
        this.creationFormSchema = creationFormSchema;
        this.editionFormSchema  = editionFormSchema;
        this.dialogService      = dialogService;
    }

    /*
     * Updates header subtitle
     *
     * @returns {string}
     */
    updateHeaderSubtitle() {
        this.headerSubtitle = this.diploma.name;
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise|Promise<void>}
     */
    activate(params) {
        return this.fetchData(params.id).then((response) => {
            this.initialModel = this.creationFormSchema.model();
            this.model        = this.creationFormSchema.model();

            this.creationSchema      = this.creationFormSchema.schema(this);
            this.editionSchema       = this.editionFormSchema.schema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);

            this.defineDatatable();

            return response;
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.updateHeaderSubtitle();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.legislation.applicability',
            repository:         {
                search:  (criteria) => this.repository.search(this.diploma.id, criteria),
                destroy: (id) => this.repository.destroy(id),
            },
            edit:               null,
            destroy:            true,
            destroySelected:    false,
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-pencil7',
                    tooltip: 'form.button.edit',
                    visible: !this.readonly,
                    action:  (row) => this.repository.find(row.id).then((response) => {
                        this.creationSchemaVisible = false;
                        this.model.assign(response);
                        this.editionSchemaVisible = true;
                    }),
                },
                {
                    icon:    'icon-attachment',
                    tooltip: 'form.button.files',
                    action:  (row) => this.dialogService.open({
                            viewModel: ListFilesModal,
                            model:     {
                                settings:            this.fileSettings,
                                relatableModel:      row,
                                relatableRepository: this.filesRepository,
                            },
                        })
                        .whenClosed(() => this.datatable.instance.reload()),
                    badge:   {
                        visible: (row) => row.files_count > 0,
                        classes: (row) => 'badge-primary pull-right',
                        content: (row) => row.files_count,
                    },
                },
                {
                    icon:    'icon-clipboard3',
                    tooltip: 'form.button.action-plan',
                    action:  (row) => this.dialogService.open({
                            viewModel: ActionPlanListing,
                            model:     row,
                        })
                        .whenClosed(() => this.datatable.instance.reload()),
                    badge:   {
                        visible: (row) => row.plan_actions_count > 0,
                        classes: (row) => 'badge-primary pull-right',
                        content: (row) => row.plan_actions_count,
                    },
                },
            ],
            options:            [
                {
                    label:   'form.button.generate-report',
                    icon:    'icon-file-pdf',
                    visible: !this.readonly,
                    action:  () => this.dialogService.open({
                        viewModel: GenerateApplicabilityReport,
                        model:     {
                            diploma: this.diploma,
                        },
                    }),
                },
            ],
            buttons:            [
                {
                    label:   'form.button.notify',
                    icon:    'icon-envelop2',
                    color:   'light',
                    visible: !this.readonly,
                    action:  () => this.dialogService.open({
                        viewModel: ConfirmNotifyUsers,
                    }).whenClosed((dialogResponse) => {
                        if (!dialogResponse.wasCancelled) {
                            this.repository.notify(this.diploma.id).then((response) => this.appContainer.notifier.responseNotice(response));
                        }
                    }),
                },
                {
                    label:   'form.button.create-new',
                    icon:    'icon-plus3',
                    color:   'success',
                    visible: !this.readonly,
                    action:  () => {
                        this.resetForm();
                        this.editionSchemaVisible  = false;
                        this.creationSchemaVisible = true;
                    },
                },
            ],
            selectable:         true,
            showInfo:           false,
            showPagination:     false,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'diploma_classification',
                    name:  'diploma_classifications.name',
                    title: 'form.field.classification',
                },
                {
                    data:  'applicable_sector',
                    name:  'company_organizations.acronym',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'applicability_type',
                    name:  'applicability_types.name',
                    title: 'form.field.applicability-type',
                },
                {
                    data:      'applicability_notes',
                    name:      'diploma_applicabilities.applicability_notes',
                    title:     'form.field.applicability-notes',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/applicability-notes/index'),
                },
            ],
        };
    }

    /**
     * Fetches data from server
     *
     * @param diplomaId
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(diplomaId) {
        return this.diplomasRepository.find(diplomaId).then((response) => this.diploma = response);
    }

    /**
     * Submits creation form
     */
    create() {
        this.alert = null;

        return this.repository
            .create(this.diploma.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.datatable.instance.reload();

                    this.resetForm();
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(console.log.bind(console));
    }

    /**
     * Submits edition form
     */
    update() {
        this.alert = null;

        return this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.datatable.instance.reload();

                    this.editionSchemaVisible = false;
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(console.log.bind(console));
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues().then(() => {
            this.appContainer.eventAggregator.publish('form-reseted', this.formId);

            if (nullifyAlert) {
                this.alert = null;
            }
        });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);
            resolve(true);
            reject(new Error('Error'));
        });
    }

}
