import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { PlanActionsRepository }           from 'modules/legislation/client/applicability/analysis/perform/action-plan/services/repository';
import { PlanActionAlertListing }          from 'modules/legislation/client/applicability/analysis/perform/action-plan/alerts/index';
import { EditPlanActionSemaphore }         from 'modules/legislation/client/applicability/analysis/perform/action-plan/semaphores/index';
import { CreationFormSchema }              from 'modules/legislation/client/applicability/analysis/perform/action-plan/creation-form-schema';
import { EditionFormSchema }               from 'modules/legislation/client/applicability/analysis/perform/action-plan/edition-form-schema';
import { PlanActionTasksListing }          from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/index';
import { DialogController, DialogService } from 'aurelia-dialog';

@inject(AppContainer, PlanActionsRepository, CreationFormSchema, EditionFormSchema, DialogService, DialogController)
export class ActionPlanListing extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.action-plan',
        size:    'modal-full',
        buttons: false,
    };

    creationSchemaVisible = false;
    editionSchemaVisible  = false;

    discardable = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param creationFormSchema
     * @param editionFormSchema
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, repository, creationFormSchema, editionFormSchema, dialogService, dialogController) {
        super(appContainer);

        this.repository         = repository;
        this.creationFormSchema = creationFormSchema;
        this.editionFormSchema  = editionFormSchema;
        this.dialogService      = dialogService;
        this.dialogController   = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(diplomaApplicability) {
        this.diplomaApplicability = diplomaApplicability;

        this.defineDatatable();

        return this.fetchData().then((response) => {
            this.initialModel = this.creationFormSchema.model(this);
            this.model        = this.creationFormSchema.model(this);

            this.creationSchema = this.creationFormSchema.schema(this);
            this.editionSchema  = this.editionFormSchema.schema(this);

            return response;
        });
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          (criteria) => this.repository.search(this.diplomaApplicability.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:                false,
            destroy:             true,
            actionsContextMenu:  true,
            actions:             [
                {
                    icon:    'icon-pencil7',
                    tooltip: 'form.button.edit',
                    visible: !this.readonly,
                    action:  (row) => this.repository.find(row.id).then((response) => {
                        this.creationSchemaVisible = false;
                        this.model.assign(response);
                        this.editionSchemaVisible = true;
                    }),
                },
                {
                    icon:    'icon-task',
                    tooltip: 'form.button.tasks',
                    action:  (row) => this.dialogService.open({
                        viewModel: PlanActionTasksListing,
                        model:     row,
                    }),
                },
                {
                    icon:    'icon-warning',
                    tooltip: 'form.button.alerts',
                    action:  (row) => this.dialogService.open({
                        viewModel: PlanActionAlertListing,
                        model:     row,
                    }),
                },
                {
                    icon:    'icon-traffic-lights',
                    tooltip: 'form.button.semaphores',
                    action:  (row) => this.dialogService.open({
                        viewModel: EditPlanActionSemaphore,
                        model:     row,
                    }),
                },
            ],
            options:             [],
            buttons:             [
                {
                    label:   'form.button.create-new',
                    icon:    'icon-plus3',
                    color:   'success',
                    visible: !this.readonly,
                    action:  () => {
                        this.resetForm();
                        this.editionSchemaVisible  = false;
                        this.creationSchemaVisible = true;
                    },
                },
            ],
            selectable:          false,
            showOptionsDropdown: false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'order',
                    name:  'plan_actions.order',
                    title: 'form.field.order',
                },
                {
                    data:  'description',
                    name:  'plan_actions.description',
                    title: 'form.field.description',
                },
                {
                    data:  'periodicity',
                    name:  'time_intervals.name',
                    title: 'form.field.periodicity',
                },
                {
                    data:       'responsible',
                    name:       'plan_actions.responsible',
                    title:      'form.field.responsible',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:  'first_task_implementation_deadline',
                    name:  'plan_actions.first_task_implementation_deadline',
                    title: 'form.field.implementation-deadline(1st-task)',
                },
            ],
        };
    }

    /**
     * Submits creation form
     */
    create() {
        this.alert = null;

        // calls repository create method
        this.repository.create(this.diplomaApplicability.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.appContainer.notifier.successNotice(response.message);
                    this.resetForm();
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch((error) => console.log(error));
    }

    /**
     * Submits edition form
     */
    update() {
        this.alert = null;

        // calls repository create method
        this.repository.update(this.model.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.appContainer.notifier.successNotice(response.message);

                    this.editionSchemaVisible = false;
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch((error) => console.log(error));
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues().then(() => {
            this.appContainer.eventAggregator.publish('form-reseted', this.formId);

            if (nullifyAlert) {
                this.alert = null;
            }
        });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);
            resolve(true);
            reject(new Error('Error'));
        });
    }

}
