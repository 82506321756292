import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, DialogController)
@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
export class ConfirmNotifyUsers extends CustomDialog {

    defaultSettings = {
        title:         'dialog.title.notify-users',
        body:          'dialog.body.notify-users',
        buttons:       false,
        customButtons: [],
        cancelButton:  {
            class:  'btn-link',
            action: this.cancel.bind(this),
            label:  'dialog.button.cancel',
        },
        okButton:      {
            class:  'btn-primary',
            action: this.submit.bind(this),
            label:  'dialog.button.confirm-notify-users',
        },
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.customButtons.push(this.settings.cancelButton);
        this.settings.customButtons.push(this.settings.okButton);
    }

}
