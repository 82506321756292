import { BaseModel } from 'modules/core/models/base-model';

export class ApplicabilityAnalysis extends BaseModel {

    diploma_classification_id = null;
    applicable_sector_id      = null;
    applicability_type_id     = null;
    applicability_notes       = null;
    requires_revision         = false;
    plan_actions              = [];

    diploma_classifications = [];
    applicable_sectors      = [];

}
