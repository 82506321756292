import { inject }                  from 'aurelia-framework';
import { TimeIntervalsRepository } from 'modules/administration/time-intervals/services/repository';
import { PlanActionAlert }         from 'modules/legislation/client/applicability/analysis/perform/models/plan-action-alert';

@inject(TimeIntervalsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param timeIntervalsRepository
     */
    constructor(timeIntervalsRepository) {
        this.timeIntervalsRepository = timeIntervalsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {PlanActionAlert}
     */
    model() {
        let model = new PlanActionAlert();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.periodicity_value = {
            type:  'number',
            key:   'periodicity_value',
            label: 'form.field.periodicity(value)',
            size:  2,
        };

        this.periodicity_id = {
            type:         'select2',
            key:          'periodicity_id',
            label:        'form.field.periodicity(time-unit)',
            size:         4,
            options:      [],
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
        };

        this.start_time_interval_value = {
            type:  'number',
            key:   'start_time_interval_value',
            label: 'form.field.start-time-interval-value',
            size:  2,
        };

        this.start_time_interval_id = {
            type:         'select2',
            key:          'start_time_interval_id',
            label:        'form.field.start-time-interval',
            size:         4,
            options:      [],
            remoteSource: this.timeIntervalsRepository.active.bind(this.timeIntervalsRepository),
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.periodicity_value, this.periodicity_id],
            [this.start_time_interval_value, this.start_time_interval_id],
            [this.buttons],
        ];
    }

}
