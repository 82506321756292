import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { PlanActionsRepository }           from 'modules/legislation/client/applicability/analysis/perform/action-plan/services/repository';
import { PlanActionTasksRepository }       from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/services/repository';
import { PlanActionTaskFollowupsListing }  from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/followups/index';
import { CreatePlanActionTask }            from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/create/index';
import { EditPlanActionTask }              from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/edit/index';
import { DialogController, DialogService } from 'aurelia-dialog';

@inject(AppContainer, PlanActionsRepository, PlanActionTasksRepository, DialogController, DialogService)
export class PlanActionTasksListing extends BaseFormViewModel {

    @bindable settings = {
        title:   'text.task',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param planActionsRepository
     * @param planActionTasksRepository
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, planActionsRepository, planActionTasksRepository, dialogController, dialogService) {
        super(appContainer);

        this.planActionsRepository     = planActionsRepository;
        this.planActionTasksRepository = planActionTasksRepository;
        this.dialogController          = dialogController;
        this.dialogService             = dialogService;
    }

    /**
     * Handles bind event
     */
    activate(planAction) {
        this.planAction = planAction;

        this.settings.title = this.appContainer.i18n.tr('text.task') + ' - ' + this.planAction.description;

        return this.planActionsRepository.details(this.planAction.id).then(response => this.planAction = response).then(() => this.defineDatatable());
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          (criteria) => this.planActionTasksRepository.search(this.planAction.id, criteria),
                destroy:         false,
                destroySelected: false,
            },
            show:                false,
            destroy:             false,
            destroySelected:     false,
            actions:             [
                {
                    icon:    'icon-pencil7',
                    tooltip: 'form.button.edit',
                    action:  (row) => this.dialogService.open({
                        viewModel: EditPlanActionTask,
                        model:     row,
                    }).whenClosed((response) => {
                        if (!response.wasCancelled) {
                            this.datatable.instance.reload();
                        }
                    }),
                },
                {
                    icon:    'icon-pencil7',
                    tooltip: 'form.button.followups',
                    visible: (row) => row.has_followups,
                    action:  (row) => this.dialogService.open({
                        viewModel: PlanActionTaskFollowupsListing,
                        model:     row,
                    }),
                },
            ],
            options:             [],
            buttons:             [
                {
                    label:   'form.button.create-new',
                    icon:    'icon-plus3',
                    color:   'success',
                    visible: !this.planAction.has_pending_tasks,
                    action:  () => this.dialogService.open({
                        viewModel: CreatePlanActionTask,
                        model:     this.planAction,
                    }).whenClosed((response) => {
                        if (!response.wasCancelled) {
                            this.datatable.instance.reload();
                        }
                    }),
                },
            ],
            selectable:          false,
            showOptionsDropdown: false,
            sorting:             {
                column:    0,
                direction: 'desc',
            },
            columns:             [
                {
                    data:  'responsible',
                    name:  'users.name',
                    title: 'form.field.responsible',
                },
                {
                    data:  'implementation_deadline',
                    name:  'plan_action_tasks.implementation_deadline',
                    title: 'form.field.implementation-deadline',
                },
                {
                    data:  'implemented_at',
                    name:  'plan_action_tasks.implemented_at',
                    title: 'form.field.implementation-date',
                },
                {
                    data:  'observations',
                    name:  'plan_action_tasks.observations',
                    title: 'form.field.observations',
                },
                {
                    data:  'conformity_status',
                    name:  'plan_action_conformity_statuses.name',
                    title: 'form.field.conformity',
                    type:  'label',
                },
                {
                    data:  'task_status',
                    name:  'plan_action_task_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
