import { inject, transient }                from 'aurelia-framework';
import { ApplicabilityAnalysis }            from 'modules/legislation/client/applicability/analysis/perform/models/applicability-analysis';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { CompanyOrganizationsRepository }   from 'modules/administration/company-organizations/services/repository';
import { ApplicabilityTypesRepository }     from 'modules/legislation/management/applicability-types/services/repository';
import { TreeModal }                        from 'modules/administration/tree-modal/index';
import { DialogService }                    from 'aurelia-dialog';

@transient()
@inject(DiplomaClassificationsRepository, CompanyOrganizationsRepository, ApplicabilityTypesRepository, DialogService)
export class EditionFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param diplomaClassificationsRepository
     * @param companyOrganizationsRepository
     * @param applicabilityTypesRepository
     * @param dialogService
     */
    constructor(diplomaClassificationsRepository, companyOrganizationsRepository, applicabilityTypesRepository, dialogService) {
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
        this.applicabilityTypesRepository     = applicabilityTypesRepository;
        this.dialogService                    = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ApplicabilityAnalysis}
     */
    model() {
        let model = new ApplicabilityAnalysis();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.diploma_classification_id = {
            type:         'diploma-classifications-select',
            key:          'diploma_classification_id',
            label:        'form.field.classification',
            size:         4,
            remoteSource: () => this.diplomaClassificationsRepository.active().then((diplomaClassifications) => {
                let availableDiplomaClassifications = diplomaClassifications.filter(
                    (diplomaClassification) => (!viewModel.diploma.isSticky || diplomaClassification.subscribed) && inArray(viewModel.diploma.classifications, diplomaClassification.id),
                );

                viewModel.displayEditionSchemaNotice = !availableDiplomaClassifications.length;

                return availableDiplomaClassifications;
            }),
        };

        this.applicable_sector_id = {
            type:           'select2',
            key:            'applicable_sector_id',
            label:          'form.field.applicable-sector',
            size:           4,
            remoteSource:   () => this.companyOrganizationsRepository.active(),
            processResults: (item) => {
                item.name = item.acronym + ' - ' + item.name;

                return item;
            },
            inputGroup:     {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: this.companyOrganizationsRepository,
                            },
                            onClick:    (event, data) => {
                                viewModel.model.applicable_sector_id = data.node.key;
                            },
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.applicability_type_id = {
            type:         'select2',
            key:          'applicability_type_id',
            label:        'form.field.applicability-type',
            size:         4,
            remoteSource: () => this.applicabilityTypesRepository.active(),
        };

        this.applicability_notes = {
            type:     'textarea',
            key:      'applicability_notes',
            label:    'form.field.applicability-notes',
            size:     12,
            required: false,
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => {
                viewModel.displayEditionSchemaNotice = false;
                viewModel.editionSchemaVisible       = false;
            },
            attributes: {
                class: 'btn btn-light float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.update(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
                this.closeButton,
            ],
        };

        return [
            [this.diploma_classification_id, this.applicable_sector_id, this.applicability_type_id],
            [this.applicability_notes],
            [this.buttons],
        ];
    }

}
