import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { BooleanOption }                   from 'modules/administration/models/boolean-option';
import { ApplicabilityAnalysisRepository } from 'modules/legislation/client/applicability/analysis/services/repository';
import { FormSchema }                      from 'modules/legislation/client/applicability/analysis/perform/report/form-schema';
import { DialogController }                from 'aurelia-dialog';

@inject(AppContainer, ApplicabilityAnalysisRepository, FormSchema, DialogController)
export class GenerateApplicabilityReport extends BaseFormViewModel {

    formId = 'legislation-client-applicability-analysis-generate-report-form';

    @bindable settings = {
        buttons: false,
        size:    'modal-full',
        title:   'form.title.view-record',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
        this.self             = this;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return true;
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstructions) {
        super.activate(navigationInstructions);

        this.diploma = params.diploma;

        this.settings.title = this.appContainer.i18n.tr('form.title.generate-report') + ' - ' + params.diploma.name;

        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Download filtered report
     */
    downloadReport() {
        this.alert = null;

        if (this.validateFields()) {
            this.repository.downloadReport(this.diploma.id, this.model);
        }
    }

    /**
     * Validates if required fields are filled
     *
     * @return {boolean}
     */
    validateFields() {
        let booleanOptionFieldsFilled =
                this.isBooleanOption(this.model.include_actions) &&
                this.isBooleanOption(this.model.include_legal_connections) &&
                this.isBooleanOption(this.model.include_resume);

        if (!booleanOptionFieldsFilled) {
            this.alert = this.dangerAlertMessage(
                this.appContainer.i18n.tr('form.message.failure.action-failed'),
                `<li>${this.appContainer.i18n.tr('validation.message.ensure-required-fields-are-filed-in')}</li>`,
            );
        }

        return booleanOptionFieldsFilled;
    }

    /**
     * Returns if the boolean option field is filled
     *
     * @param field
     *
     * @returns {boolean}
     */
    isBooleanOption(field) {
        return [BooleanOption.YES, BooleanOption.NO].includes(field);
    }

}
