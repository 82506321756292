import { inject, transient }                from 'aurelia-framework';
import { ApplicabilityTypesRepository }     from 'modules/legislation/management/applicability-types/services/repository';
import { CompanyOrganizationsRepository }   from 'modules/administration/company-organizations/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@transient()
@inject(ApplicabilityTypesRepository, CompanyOrganizationsRepository, DiplomaClassificationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        applicable_sectors:        [],
        diploma_classifications:   [],
        applicability_types:       [],
        include_actions:           null,
        include_legal_connections: null,
        include_resume:            null,
    };

    /**
     * Constructor
     *
     * @param applicabilityTypesRepository
     * @param companyOrganizationsRepository
     * @param diplomaClassificationsRepository
     */
    constructor(applicabilityTypesRepository, companyOrganizationsRepository, diplomaClassificationsRepository) {
        this.applicabilityTypesRepository     = applicabilityTypesRepository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        return this.modelDefaults;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.applicability_types = {
            type:         'multiselect-native',
            key:          'applicability_types',
            id:           'applicability_applicability_types',
            label:        'form.field.applicability-types',
            size:         3,
            remoteSource: this.applicabilityTypesRepository.active.bind(this.applicabilityTypesRepository),
        };

        this.include_actions = {
            type:  'boolean-options-select',
            key:   'include_actions',
            id:    'applicability_include_actions',
            label: 'form.field.include-actions',
            size:  3,
        };

        this.include_legal_connections = {
            type:  'boolean-options-select',
            key:   'include_legal_connections',
            id:    'applicability_include_legal_connections',
            label: 'form.field.include-legal-connections',
            size:  3,
        };

        this.include_resume = {
            type:  'boolean-options-select',
            key:   'include_resume',
            id:    'applicability_include_resume',
            label: 'form.field.include-resume',
            size:  3,
        };

        this.applicable_sectors = {
            type:         'fancytree',
            key:          'applicable_sectors',
            id:           'applicability_applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         6,
            options:      [],
            remoteSource: () => this.companyOrganizationsRepository.tree(true),
            settings:     {
                repository: viewModel.companyOrganizationsRepository,
            },
        };

        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            id:           'applicability_diploma_classifications',
            label:        'form.field.classifications',
            size:         6,
            remoteSource: () => this.diplomaClassificationsRepository.tree(true),
            settings:     {
                repository: viewModel.diplomaClassificationsRepository,
                selectMode: 3,
            },
        };

        return [
            [this.applicability_types, this.include_actions, this.include_legal_connections, this.include_resume],
            [this.applicable_sectors, this.diploma_classifications],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.generateReportButton = {
            type:       'submit',
            label:      'form.button.generate-report',
            action:     () => viewModel.downloadReport(),
            attributes: {
                class: 'btn btn-primary float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-pdf',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.generateReportButton,
                this.cancelButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
