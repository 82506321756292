import { bindable, inject }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { PlanActionTasksRepository } from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/services/repository';
import { FormSchema }                from 'modules/legislation/client/applicability/analysis/perform/action-plan/tasks/edit/form-schema';
import { DialogController }          from 'aurelia-dialog';

@inject(AppContainer, PlanActionTasksRepository, FormSchema, DialogController)
export class EditPlanActionTask extends BaseFormViewModel {

    @bindable settings = {
        title: 'form.title.edit-record',
        size:  'modal-md',
    };

    discardable = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(planActionTask) {
        this.planActionTask = planActionTask;

        //if (this.planActionTask.overview) {
        //    this.settings.title += ' - ' + this.planActionTask.overview;
        //}

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.fetchData().then((response) => {
            this.initialModel.assign(planActionTask);
            this.model.assign(planActionTask);

            this.schema = this.formSchema.schema(this);

            return response;
        });
    }

    /**
     * Submits form
     */
    submit() {
        return this.repository.update(this.planActionTask.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    return this.dialogController.ok();
                }

                this.alert = this.alertMessage(response.status, response.message, response.errors);
            });
    }

}
