import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class PlanActionsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param applicabilityId
     * @param criteria
     *
     * @returns {*}
     */
    search(applicabilityId, criteria) {
        return this.httpClient.post('legislation/client/diplomas/applicability/' + applicabilityId + '/analysis/plan-actions/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param applicabilityId
     * @param data
     *
     * @returns {*}
     */
    create(applicabilityId, data) {
        return this.httpClient.post('legislation/client/diplomas/applicability/' + applicabilityId + '/analysis/plan-actions', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/diplomas/applicability/analysis/plan-actions/${id}`);
    }

    /**
     * Returns plan action details from server
     *
     * @param id
     *
     * @returns {*}
     */
    details(id) {
        return this.httpClient.get(`legislation/client/diplomas/applicability/analysis/plan-actions/${id}/details`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/client/diplomas/applicability/analysis/plan-actions/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete('legislation/client/diplomas/applicability/analysis/plan-actions/' + id);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/client/diplomas/applicability/analysis/plan-actions/destroy-selected', {ids: ids});
    }

}
