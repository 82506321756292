import { bindable, inject }           from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { PlanActionAlertsRepository } from 'modules/legislation/client/applicability/analysis/perform/action-plan/alerts/services/repository';
import { FormSchema }                 from 'modules/legislation/client/applicability/analysis/perform/action-plan/alerts/form-schema';
import { DialogController }           from 'aurelia-dialog';

@inject(AppContainer, PlanActionAlertsRepository, FormSchema, DialogController)
export class PlanActionAlertListing extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.alerts',
        size:    'modal-full',
        buttons: false,
    };

    alert = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(planAction) {
        this.planAction = planAction;

        this.defineDatatable();

        this.initialModel = this.formSchema.model(this);
        this.model        = this.formSchema.model(this);

        this.schema = this.formSchema.schema(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          (criteria) => this.repository.search(this.planAction.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:             true,
            actions:             [],
            options:             [],
            buttons:             [],
            selectable:          false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'periodicity_value',
                    name:  'plan_action_alerts.periodicity_value',
                    title: 'form.field.periodicity(value)',
                },
                {
                    data:  'periodicity',
                    name:  'time_intervals.name',
                    title: 'form.field.periodicity',
                },
                {
                    data:  'start_time_interval_value',
                    name:  'plan_action_alerts.start_time_interval_value',
                    title: 'form.field.start-time-interval-value',
                },
                {
                    data:  'start_time_interval',
                    name:  'time_intervals.name',
                    title: 'form.field.start-time-interval',
                },
            ],
        };
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        this.repository.create(this.planAction.id, this.model)
            .then((response) => {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.resetForm();

                    this.datatable.instance.reload();
                }
            })
            .catch((error) => console.log(error));
    }

}
