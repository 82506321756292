import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class PlanActionTasksRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param planActionId
     * @param criteria
     *
     * @returns {*}
     */
    search(planActionId, criteria) {
        return this.httpClient.post(`legislation/client/diplomas/applicability/analysis/plan-actions/${planActionId}/tasks/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param planActionId
     * @param data
     *
     * @returns {*}
     */
    create(planActionId, data) {
        return this.httpClient.post(`legislation/client/diplomas/applicability/analysis/plan-actions/${planActionId}/tasks`, data);
    }

    /**
     * Updates a record
     *
     * @param planActionTaskId
     * @param data
     *
     * @returns {*}
     */
    update(planActionTaskId, data) {
        return this.httpClient.put(`legislation/client/diplomas/applicability/analysis/plan-actions/tasks/${planActionTaskId}`, data);
    }

}
