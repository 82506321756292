import { bindable, inject }               from 'aurelia-framework';
import { AppContainer }                   from 'resources/services/app-container';
import { BaseFormViewModel }              from 'base-form-view-model';
import { PlanActionSemaphoresRepository } from 'modules/legislation/client/applicability/analysis/perform/action-plan/semaphores/services/repository';
import { DialogController }               from 'aurelia-dialog';
import { FormSchema }                     from 'modules/legislation/client/applicability/analysis/perform/action-plan/semaphores/form-schema';

@inject(AppContainer, PlanActionSemaphoresRepository, DialogController, FormSchema)
export class EditPlanActionSemaphore extends BaseFormViewModel {

    @bindable settings = {
        title: 'form.title.semaphores',
    };

    discardable = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles bind event
     */
    activate(planAction) {
        this.planAction = planAction;

        this.initialModel = this.formSchema.model(this);
        this.model        = this.formSchema.model(this);

        return this.repository.find(planAction.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.schema = this.formSchema.schema(this);
        });
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        return this.repository.update(this.planAction.id, this.model)
            .then((response) => {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);
                }

                return response.status;
            })
            .catch((error) => console.log(error));
    }

}
